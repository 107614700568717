<template>
	<div class="pv container-fluid">
		<div class="mt-3">
			<h3 class="pv__title mb-2">Voice Cloning</h3>
			<h5 class="font-weight-normal">{{ $t('voice-cloning--desc') }}</h5>
			<div class="d-flex justify-content-end align-items-center">
				<div class="pv__subscription d-flex align-items-center ml-auto dropdown">
					<img
						:src="require('@/assets/images/icons/ic_subscription_info.svg')"
						class="pv__subscription-icon"
						alt=""
					/>
					<div class="pv__tooltip dropdown-menu">
						<span>{{ $t('model-allocation') }} <b class="cursor-pointer pv__tooltip--topup" @click="$router.push({ name : 'TopUp' })">{{ $t('top-up-more') }}</b></span>
						<div class="pv__tooltip--package d-flex align-items-center mt-2">
							<div class="pv__tooltip--package-wrapper p-2">
								<div class="pv__tooltip--package-wrapper-inner d-flex align-items-start">
									<img src="@/assets/images/icons/ic_cct_1.svg" alt="" width="8">
									<div class="pv__tooltip--package-quota d-flex flex-column">
										<p class="m-0 tooltip--package__text">Monolingual</p>
										<p class="m-0 tooltip--package__quota">{{ allocation?.voice_model_monolingual_quota || '0' }}<span class="tooltip--package__text">{{ $t('models') }}</span></p>
									</div>
								</div>
							</div>
							<div class="pv__tooltip--package-wrapper p-2">
								<div class="pv__tooltip--package-wrapper-inner d-flex align-items-start">
									<img src="@/assets/images/icons/ic_cct_2.svg" alt="" width="10">
									<div class="pv__tooltip--package-quota d-flex flex-column">
										<p class="m-0 tooltip--package__text">Multilingual</p>
										<p class="m-0 tooltip--package__quota">{{ allocation?.voice_model_multilingual_quota || '0' }}<span class="tooltip--package__text">{{ $t('models') }}</span><span class="tooltip--package__exp ml-3" v-if="allocation?.expired_date && allocation?.package_subscriptions_id != '658bae0b6f59934d88b742f1' && allocation?.audio_multilingual_generates_quota">exp. {{ getMonthDate(allocation?.expired_date) }}</span></p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<p class="pv__subscription-text m-0">{{ allocation?.voice_model_multilingual_quota + allocation?.voice_model_monolingual_quota || '0' }} {{ $t('models') }}</p>
				</div>
				<button class="pv__button-cv btn ml-3" @click="$bvModal.show('cct-modal')">{{ $t('clone-new') }}</button>
			</div>
		</div>
		<PvTable v-if="!loading" :items="items" :fields="fields" @update-table="handleUpdateTable"/>
		<div v-if="items.length == 0 && !loading" class="pv__ydhav d-flex flex-column text-center justify-content-center">
			<h4 class="m-0 font-weight-normal" v-html="$t('clone-new--p')"></h4>
			<div>
				<button class="pv__button-cv btn" @click="$bvModal.show('cct-modal')">{{ $t('clone-new') }}</button>
			</div>
		</div>

		<div v-if="loading" class="pv__load d-flex align-items-center justify-content-center">
			<h4 class="m-0 font-weight-normal">Wait...</h4>
		</div>

		<TTVRecordVoiceModal id="pv-record-voice" :loading-audio="loadingPlayAudio" :is-generate="isGenerate" :is-play="isPlay" @play-voice-clone-sample="handleVoiceCloneSample" @rest-vs="handleResetVs" @create-vc="$bvModal.show('cct-modal'), $bvModal.hide('pv-record-voice')"/>
		<PVInstruction @continue="$bvModal.show('pvrecord-modal'), $bvModal.hide('cct-modal'), $bvModal.hide('pvi-modal')"/>
		<PVRecord @update-table="handleUpdateTable" @reset="selectedCloneType = null" @show-instruction="$bvModal.hide('pvrecord-modal'), $bvModal.show('pvi-modal')" :selected="selectedCloneType"/>
		<ChooseCloneTypeModal :selected="selectedCloneType" @hidden:cct-closed="selectedCloneType = null" @click:change-selected-clone-type="handleChangeSelectedCloneType" @click:next="handleClickNextCct"/>
		<QuotaModelsAlert :type="selectedCloneType"/>
		<VCMultilingualError @retry="handleRetryVCError" @cancel="handleCancelVCError"/>
		<ServerErrorModal />
	</div>
</template>

<script>
import PvTable from '@/components/videos/personal-voices/Table'
import TTVRecordVoiceModal from '@/components/modal/TTVRecordVoice';
import PVInstruction from '@/components/modal/PVInstruction';
import PVRecord from '@/components/modal/PVRecord';
import QuotaModelsAlert from '@/components/modal/QuotaModelsAlert';
import VCMultilingualError from '@/components/modal/VCMultilingualError';
import ServerErrorModal from '@/components/modal/ServerError';
import ChooseCloneTypeModal from '../../../../components/modal/ChooseCloneType.vue';
import $ from "jquery"

import { voiceCloneSample, getVoiceModel } from '@/services/ttv/ttv.service.js'
import { subscriptionsStatus } from '@/services/subscriptions/subscriptions.service'


export default {
	components: {
		PvTable,
		TTVRecordVoiceModal,
		PVInstruction,
		ChooseCloneTypeModal,
		QuotaModelsAlert,
		VCMultilingualError,
		ServerErrorModal,
		PVRecord
	},
	data() {
		return {
			isGenerate: false,
			isPlay: false,

			voiceCloneSampleUrl: null,
			selectedCloneType: null,
			selectedCloneTypeAlert: null,
			allocation: null,

			loadingPlayAudio: false,
			loading: true,

			finalFormVoiceSample: {
				text: '',
				speaker: ''
			},
			fields: [
				{
					key : 'name',
					label : this.$t('models-name')
				},
				{
					key : 'type',
					label : this.$t('type'),
					class: 'text-left'
				},
				{
					key : 'created',
					label : this.$t('created'),
					class: 'text-center'
				},
				{
					key : 'action',
					label : '',
				},
			],
			items: []
		}
	},

	mounted() {
        $("div.pv").parent().addClass('pb-0');
		this.getPersonalVoice()
		this.getAllocation()
	},

	beforeDestroy() {
		$("div.pv").parent().addClass('pb-0');
	},

	methods: {
		handleVoiceCloneSample(val) {
			if(this.finalFormVoiceSample.text == val.text && this.finalFormVoiceSample.speaker == val.speaker) {
				this.playVoiceCloneSample()
			} else {
				this.isGenerate = true
				voiceCloneSample(val)
				.then((response) => {
				let audio = new Audio(response.data)
				this.voiceCloneSampleUrl = audio
				this.isGenerate = false
				this.playVoiceCloneSample()
				})
			}
			this.finalFormVoiceSample.text = val.text
			this.finalFormVoiceSample.speaker = val.speaker
		},

		handleResetVs(){
			if(this.voiceCloneSampleUrl) {
				clearInterval(this.voiceCloneSampleUrl.intervalId)
				this.voiceCloneSampleUrl.pause()
			}
			this.voiceCloneSampleUrl = null
			this.isGenerate = false
			this.isPlay = false
			this.finalFormVoiceSample = {
				text: '',
				speaker: ''
			}
		},

		playVoiceCloneSample(){
			this.voiceCloneSampleUrl
			this.voiceCloneSampleUrl.onwaiting = () => {
                this.loadingPlayAudio = true;
            };

            //turn off loading if music already rendering
            this.voiceCloneSampleUrl.onplaying = () => {
                this.loadingPlayAudio = false;
            };

			if(!this.voiceCloneSampleUrl.paused) {
				this.isPlay = false
				clearInterval(this.voiceCloneSampleUrl.intervalId)
				this.voiceCloneSampleUrl.pause()
			} else {
				this.isPlay = true
				this.voiceCloneSampleUrl.play()

				this.voiceCloneSampleUrl.addEventListener('ended', () => {
					this.isPlay = false;
				});

				this.voiceCloneSampleUrl.intervalId = setInterval(() => {
					this.voiceCloneSampleUrl.paused ? clearInterval(this.voiceCloneSampleUrl.intervalId) : null;
					if (this.voiceCloneSampleUrl.currentTime === this.voiceCloneSampleUrl.duration) {
						this.isPlay = false
					}
				}, 100)
			}
		},

		getPersonalVoice() {
			this.loading = true
			getVoiceModel()
			.then((response) => {
				if(response.status == 200) {
					let data = response.data
					const promises = data.map((e) => {
						return this.getDurationFromAPI(e.path_voice)
							.then((duration) => {
								return {
									id: e.id,
									path_voice: e.path_voice,
									lang: e.lang,
									character_name: e.character_name,
									duration: duration,
									created_at: e.created_at,
									type: e.type
								};
							})
					});
					Promise.all(promises)
                    .then((items) => {
                        this.items = items;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.loading = false;
                    });
					this.loading = false
				} else {
					this.loading = false
				}
			})
		},

		getDurationFromAPI(path) {
			return new Promise((resolve) => {
				const audio = new Audio(path);
				audio.addEventListener('loadedmetadata', () => {
					let duration = 0;
					if(audio.duration) {
						duration = audio.duration
					}
					resolve(duration);
				});
				audio.addEventListener('error', () => {
					resolve(0);
				});
			});
		},

		handleClickNextCct() {
			if(!this.allocation?.voice_model_monolingual_quota && this.selectedCloneType == 'monolingual') {
				this.$bvModal.show('qm-modal')
			} else if (!this.allocation?.voice_model_multilingual_quota && this.selectedCloneType == 'multilingual') {
				this.$bvModal.show('qm-modal')
			} else {
				this.$bvModal.hide('cct-modal')
				this.$bvModal.show('pvi-modal')
			}
		},

		async getAllocation() {
			await subscriptionsStatus()
			.then((res) => {
				if(res.status) {
					this.allocation = res.data
				}
			})
		},

		handleChangeSelectedCloneType(newVal) {
			this.selectedCloneType = newVal
		},

		handleUpdateTable() {
			this.getPersonalVoice()
			this.getAllocation()
		},

		handleRetryVCError() {
			this.$bvModal.hide('vcm-modal')
			this.$bvModal.hide('pvrecord-modal')
			this.$bvModal.show('cct-modal')
		},

		handleCancelVCError() {
			this.$bvModal.hide('vcm-modal')
			this.$bvModal.hide('pvrecord-modal')
		},

		getMonthDate(dateTime) {
			const months = [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			];

			const dateObject = new Date(dateTime);
			const tanggal = dateObject.getUTCDate();
			const monthIndex = dateObject.getUTCMonth();
			const month = months[monthIndex];

			return `${month} ${tanggal}`;
		},
	}
}
</script>

<style scoped>
.pv {
	text-align: left;
	color: #fff;
	height: calc(100vh - 80px);
	overflow: hidden
}

.pv__title {
	font-size: 24px;
}

.pv__subtitle {
	font-size: 18px;
	font-weight: 400;
}

.pv__button-cv {
	border: 1px solid #6D75F6;
	border-radius: 4px;
	color: #6D75F6;
	font-size: 16px;
	font-weight: 600;
	transition: background-color 0.25s;
	padding: 6px 18px;
}

.pv__button-cv:hover {
	background-color: rgba(109, 117, 246, 0.2);
}

.pv__ydhav, .pv__load {
	height: 25vh;
	border-radius: 6px;
	gap: 24px;
}

.pv__ydhav {
	background: #1F1F1F;
}

.pv__subscription {
  gap: 6px;
}

.pv__subscription:hover .pv__tooltip {
  display: block;
}

.pv__tooltip {
  top: 17px;
  left: -255px !important;
  width: auto !important;
  background-color: #1F1F1F;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 15px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}

.pv__subscription-icon {
  height: 18px;
}

.pv__subscription-text {
  font-size: 16px;
  font-weight: 600;
}

.pv__tooltip--topup {
	color: #6D75F6;
}

.pv__tooltip--package {
	gap: 6px;
}

.pv__tooltip--package-wrapper {
	border-radius: 4px;
	background: #2D2D2D;
}

.pv__tooltip--package-wrapper-inner {
	gap: 4px;
}

.tooltip--package__text {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.tooltip--package__quota {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.pv__tooltip--package-quota {
	gap: 4px;
}

.tooltip--package__exp {
	color: #8C8C8C;
	font-family: Avenir Next;
	font-size: 8px;
	font-style: italic;
	font-weight: 500;
	line-height: normal;
}
</style>