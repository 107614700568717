<template>
  <div class="ap d-flex align-items-center text-white">
    <button
      type="button"
      class="ap__toggle btn p-0 rounded-0"
      :disabled="isLoading"
      :title="`${isPlaying ? 'Pause' : 'Play'} ${name}`"
      @click="toggleAudioPlayer"
    >
      <font-awesome-icon
        :icon="`fa-solid ${isPlaying ? 'fa-pause-circle' : 'fa-play-circle'}`"
        class="ap__toggle-icon"
        :class="{ 'ap__toggle-icon--small': size === 'small' }"
      />
    </button>
    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <p
            class="ap__name m-0 text-nowrap"
            :title="name"
            :class="{ 'ap__name--small': size === 'small' }"
          >
            {{ truncatedName }}
          </p>
        </div>
        <div class="col-8">
          <input
            ref="slider"
            type="range"
            class="ap__slider"
            :class="{ 'ap__slider--small': size === 'small' }"
            v-model="currentTime"
            min="0"
            :max="sliderMax"
            step="0.1"
            title="Slide the Audio"
            @input="handleInputSlider"
            @change="handleChangeSlider"
          >
        </div>
      </div>
    </div>
    <audio ref="audioPlayer" :src="src" preload="metadata" />
  </div>
</template>

<script>
import getBlobDuration from 'get-blob-duration';

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator(value) {
        return ['small', 'medium'].includes(value);
      },
      default: 'medium',
    },
  },
  emits: ['play'],
  data() {
    return {
      duration: 0,
      sliderMax: 100,
      currentTime: 0,
      isLoading: false,
      isPlaying: false,
      doSliding: false,
    };
  },
  computed: {
    truncatedName() {
      const maxLength = 20;
      const ellipsis = '...';

      return this.name.length > maxLength ? this.name.substring(0, maxLength - ellipsis.length) + '...' : this.name;
    },
  },
  methods: {
    async loadedmetadataListener() {
      if (this.src.search('blob:') >= 0) {
        this.duration = await getBlobDuration(this.src);
      } else {
        this.duration = this.$refs.audioPlayer.duration;
      }
      this.sliderMax = Math.floor(this.duration);
    },
    timeupdateListener() {
      const currentSeconds = parseFloat(this.$refs.audioPlayer.currentTime.toFixed(1));
      if (!this.doSliding) {
        this.currentTime = currentSeconds
      }

      if (currentSeconds >= parseFloat(this.$refs.audioPlayer.duration.toFixed(1)) && this.$refs.audioPlayer.paused) {
        this.isPlaying = false;
      }
    },
    calculateTime(secs) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    },
    async toggleAudioPlayer() {
      if (!this.isLoading) {
        this.isLoading = true;

        try {
          if (this.isPlaying) {
            await this.$refs.audioPlayer.pause();
            this.isPlaying = false;
          } else {
            await this.$refs.audioPlayer.play();
            this.isPlaying = true;
            this.$emit('play', this.pauseAudioPlayer, this._uid);
          }
        } catch (error) {
          console.log(error);
        }

        this.isLoading = false;
      }
    },
    async pauseAudioPlayer() {
      this.isLoading = true;

      try {
        if (this.isPlaying) {
          await this.$refs.audioPlayer.pause();
          this.isPlaying = false;
        }
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },
    handleInputSlider() {
      this.doSliding = true;
    },
    handleMouseupSlider() {
      if (this.doSliding) {
        this.doSliding = false;
      }
    },
    handleChangeSlider(e) {
      this.$refs.audioPlayer.currentTime = parseFloat(e.target.value).toFixed(1);
    },
  },
  mounted() {
    this.$refs.audioPlayer.addEventListener('loadedmetadata', this.loadedmetadataListener);
    this.$refs.audioPlayer.addEventListener('timeupdate', this.timeupdateListener);
    document.addEventListener('mouseup', this.handleMouseupSlider);
  },
  beforeDestroy() {
    this.$refs.audioPlayer.removeEventListener('loadedmetadata', this.loadedmetadataListener);
    this.$refs.audioPlayer.removeEventListener('timeupdate', this.timeupdateListener);
    document.removeEventListener('mouseup', this.handleMouseupSlider);
  },
};
</script>

<style scoped>
.ap {
  gap: 12px;
}

.ap__toggle {
  background: none;
}

.ap__toggle-icon {
  font-size: 32px;
  color: #FFFFFF;
}

.ap__toggle-icon--small {
  font-size: 24px;
}

.ap__name {
  font-weight: 500;
}

.ap__name--small {
  font-size: 13px;
}

.ap__slider {
  width: 100%;
  accent-color: #6D75F6;
  cursor: pointer;
}

.ap__slider--small {
  height: 4px;
}

.ap__time {
  font-size: 12px;
}
</style>
