<template>
	<div class="pvt">
		<b-table :items="items" :fields="fields" class="pvt__custom-table" sticky-header>
			<template #cell(name)="data">
                <PvAudioPlayer :src="data.item.path_voice" :name="data.item.character_name"/>
            </template>

			<template #cell(type)="data">
				<span class="text-white">{{ data.item.type ? capitalizeText(data.item.type) : 'Monolingual' }}</span>
            </template>

			<!-- <template #cell(duration)="data">
				<span class="text-white">{{ calculateTime(data.item.duration) }}</span>
            </template> -->

			<template #cell(created)="data">
				<span class="text-white">{{ extractDate(data.item.created_at) }}</span>
            </template>

			<template #cell(action)="data">
				<div class="dropdown pvt__dropdown">
					<button class="pvt__button-action btn p-0" type="button" data-toggle="dropdown" aria-expanded="false">
						<font-awesome-icon icon="fa-solid fa-ellipsis" class="pvt__ba-icon"/>
					</button>
					<div class="dropdown-menu">
						<a class="dropdown-item text-white px-2 cursor-pointer" @click="handleUseVoiceModel(data.item)">{{ $t('use') }}</a>
						<a class="dropdown-item text-white px-2 cursor-pointer" @click="renameVm(data.item.id, data.item.character_name)">{{ $t('rename') }}</a>
						<a class="dropdown-item text-white px-2 cursor-pointer" @click="deleteVm(data.item.id)" >{{ $t('delete') }}</a>
					</div>
				</div>
			</template>
		</b-table>
		<DeleteVoiceModelVue :is-delete="isDelete" @cancel-delete="handleCancelAction" @delete-voice="handleDeleteVoice"/>
		<RenameVoiceModelVue v-model="name" @cancel-rename="handleCancelAction" @save-name="handleSaveName"/>
	</div>
</template>

<script>
// import randomUUID from '@/helper/uuid';

import DeleteVoiceModelVue from '../../modal/DeleteVoiceModel.vue'
import RenameVoiceModelVue from '../../modal/RenameVoiceModel.vue'
import PvAudioPlayer from './AudioPlayer.vue'

import { deleteVoiceModelCustom, updateVoiceModel } from '@/services/ttv/ttv.service.js'
import { AlertUtils } from "@/mixins/AlertUtils";

export default {
	mixins: [AlertUtils],
	components : {
		PvAudioPlayer,
		DeleteVoiceModelVue,
		RenameVoiceModelVue
	},
	props: {
		items : {
			type: Array,
			default : () => {
				return []
			}
		},

		fields : {
			type: Array,
			default : () => {
				return []
			}
		},
	},
	data() {
		return {
			id : null,
			name : null,
			isDelete: false,
		}
	},

	methods: {
		deleteVm(id) {
			this.id = id
			this.$bvModal.show('dvm-modal')
		},

		renameVm(id, name) {
			this.id = id
			this.name = name
			this.$bvModal.show('rvm-modal')
		},

		handleCancelAction() {
			this.id = null
			this.name = null
		},

		handleDeleteVoice() {
			this.isDelete = true
			deleteVoiceModelCustom(this.id)
			.then((response) => {
				if(response.status == 200) {
					this.$emit('update-table')
					this.alertSuccess('Delete successfully')
					this.$bvModal.hide('dvm-modal')
				} else {
					this.alertFail('Something went wrong!')
				}
				this.isDelete = false
			})
			.catch((error) => {
				console.error(error);
				this.isDelete = false
			})
		},

		handleSaveName() {
			let formData = {
				character_name : this.name
			}
			updateVoiceModel(formData, this.id)
			.then((response) => {
				if(response.status == 200) {
					this.$emit('update-table')
					this.alertSuccess('Changes saved successfully')
					this.$bvModal.hide('rvm-modal')
				} else {
					this.alertFail('Something went wrong!')
				}
			})
		},

		handleUseVoiceModel(val) {
			// let voicesArray = []
			let voiceType = {
				language: {
					id: val.lang,
					name: 'Indonesia',
					code: 'ID',
					flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
				},
				character: {
					id: val.id,
					name: val.character_name,
					avatarSrc: 'https://ui-avatars.com/api/?name=' + val.character_name,
					sampleSrc: val.path_voice,
					lang: val.lang,
					isUseModel: true
				},
			}

			// let voices = {
			// 	id: randomUUID(),
			// 	courseId: null,
			// 	ttsId: '',
			// 	text: '',
			// 	scriptType: 'lessons',
			// 	voiceType: voiceType,
			// 	src: null,
			// 	duration: null,
			// 	actionState: 'generate',
			// 	disableAction: true,
			// 	disableDelete: true,
			// 	generateText: null,
			// 	generateSpeaker: null,
			// }
			// voicesArray.push(voices)

			const setItemPromise = new Promise((resolve, reject) => {
				try {
					sessionStorage.setItem("gv::use-voice", JSON.stringify(voiceType))
					resolve()
				} catch (error) {
					reject(error)
				}
			})

			setItemPromise.then(() => {
				this.$router.push({ name: 'Generate-Videos', params: { mode: 'new', id: '1' } })
			}).catch((error) => {
				console.error(error)
			})
		},

		calculateTime(secs) {
			const minutes = Math.floor(secs / 60);
			const seconds = Math.floor(secs % 60);
			const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
			return `${minutes}:${returnedSeconds}`;
		},

		extractDate(dateString) {
			let year, month, day;

			// Format 1: YYYY-MM-DD HH:MM:SS[...] (contoh: "2023-09-19 16:52:58[...]")
			const regexFormat1 = /^(\d{4})-(\d{2})-(\d{2})/;
			const matchFormat1 = dateString.match(regexFormat1);

			// Format 2: "Day, DD Mon YYYY HH:MM:SS GMT" (contoh: "Tue, 19 Sep 2023 16:52:58 GMT")
			const regexFormat2 = /^(\w+), (\d{2}) (\w{3}) (\d{4})/;
			const matchFormat2 = dateString.match(regexFormat2);

			if (matchFormat1) {
				year = matchFormat1[1];
				month = matchFormat1[2];
				day = matchFormat1[3];
			} else if (matchFormat2) {
				year = matchFormat2[4];
				month = this.matchMonthNameToNumber(matchFormat2[3]);
				day = matchFormat2[2];
			} else {
				year = month = day = "Invalid Format";
			}

			return `${year}-${month}-${day}`;
		},

		matchMonthNameToNumber(monthName) {
			const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
			return String(monthNames.indexOf(monthName) + 1).padStart(2, "0");
		},

		getTimeAgo(dateTime) {
			const now = new Date(new Date().getTime() + 420 * 60 * 1000)
			const formattedDateString = dateTime.replace(/(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}).*/, '$1');
			const past = new Date(formattedDateString).getTime() + 420 * 60 * 1000;

			const seconds = Math.floor((now - past) / 1000);
			console.log(seconds, now, past, formattedDateString)
			if (seconds < 60) {
				return seconds < 1 ? "a few ago" : `${seconds} seconds ago`;
			}

			const minutes = Math.floor(seconds / 60);
			if (minutes < 60) {
				return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
			}

			const hours = Math.floor(minutes / 60);
			if (hours < 24) {
				return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
			}

			const days = Math.floor(hours / 24);
			if (days < 7) {
				return days === 1 ? "1 day ago" : `${days} days ago`;
			}

			const weeks = Math.floor(days / 7);
			if (weeks < 4) {
				return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
			}

			const months = Math.floor(days / 30);
			if (months < 12) {
				return months === 1 ? "1 month ago" : `${months} months ago`;
			}

			const years = Math.floor(days / 365);
			return years === 1 ? "1 year ago" : `${years} years ago`;
		},

		capitalizeText(text) {
			return text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
		},
	}
}
</script>

<style scoped>
.pvt__custom-table {
	color: #8c8c8c;
}

.pvt__button-action {
	box-shadow: none;
	color: #fff;
}

.pvt__ba-icon {
	font-size: 16px;
}
</style>

<style>
.pvt__custom-table {
	max-height: calc(100vh - 210px);
}

.pvt__custom-table thead th,  .pvt__custom-table tbody td{
	border-bottom: 0.1px solid #2d2d2d !important;
}

.pvt__custom-table .table-b-table-default {
	color: #8c8c8c !important;
	background-color: black !important;
}

.pvt__custom-table thead tr th {
	top: -1px !important;
}

.pvt__dropdown .dropdown-menu{
	background: #2D2D2D;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	color: #fff;
	min-width: 100px !important;
	max-width: 100px !important;
}

.pvt__dropdown .dropdown-item:hover {
	background-color: #8c8c8c;
	color: #fff;
}
</style>