<template>
    <div>
        <b-modal id="dvm-modal" no-close-on-backdrop centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm" @hidden="$emit('cancel-delete')">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('dvm-modal')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center text-white mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Logout">
                <div class="mt-3">
                    <h5 class="font-weight-bold">Are you sure you want to <br> delete this voice?</h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="$bvModal.hide('dvm-modal')"><p class="mb-0 font-weight-bold">Cancel</p></button>
                    <button :disabled="isDelete" class="btn btn-delete-yes" @click="$emit('delete-voice')"><p class="mx-2 mb-0 font-weight-bold">{{ isDelete ? 'Loading...' : 'Yes' }}</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

export default {
    props: {
      isDelete: {
        type: Boolean,
        default: false
      }
    },
    setup() {

    },

    methods : {

    }
}
</script>

<style scoped>
.btn-delete-yes {
  background-color: #6d75f6;
  color: white;
  box-shadow: none !important;
}
</style>

<style>

@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#dvm-modal .modal-content {
  background-color: #222222;
  font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#dvm-modal .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

#dvm-modal .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

button {
  box-shadow: none !important;
}

</style>